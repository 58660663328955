@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

body::-webkit-scrollbar-track {
  background: #1f2937; /* Dark background for the track */
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(107, 114, 128, 0.8); /* Gray thumb */
  border-radius: 10px;
  border: 2px solid transparent; /* Adds space around the thumb for better look */
  background-clip: padding-box; /* Ensures the border is not colored */
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(107, 114, 128, 1); /* Slightly darker on hover */
}

/* Navbar styling */
nav {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

nav.black {
  background-color: #000000; /* Black at the top */
}

nav.translucent {
  background-color: rgba(31, 41, 55, 0.8); /* Adjust color and opacity as needed */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Glow effect */
}

nav.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Hamburger menu styles */
.hamburger-line {
  display: block;
  width: 25px;
  height: 2px;
  background: white;
  margin: 4px 0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.min-h-screen {
  min-height: 100vh;
}

/* Starry background */
.starry-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  animation: moveStar linear infinite;
}

@keyframes moveStar {
  0% {
    transform: translateX(0) rotate(var(--angle));
  }
  100% {
    transform: translateX(100vw) rotate(var(--angle));
  }
}

/* Blurred content effect when scrolled */
.blurred-content {
  transition: filter 0.3s ease, opacity 0.3s ease;
  filter: none;
  opacity: 1;
}

.blurred-content.blurred {
  filter: blur(20px);
  opacity: 0.3; /* Reduce opacity to further obscure content */
}

/* Overlay to make content completely unreadable */
.blur-overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
  pointer-events: none; /* Allow clicks to pass through */
}


/*sticky note */
.sticky-note {
  width: 150px;
  height: 150px;
  background: url('./Assets/stickynote.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: rotate(-5deg);
  transition: transform 0.02s, box-shadow 0.04s;
}

.sticky-note:hover {
  transform: rotate(0deg) scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.sticky-note p {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}


/* Laptop screen styling */
.laptop {
  position: relative;
  border-radius: 20px;
  background: #222;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.screen {
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.vs-code-window {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.vs-code-header {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  padding: 10px;
  border-bottom: 1px solid #333;
}

.vs-code-content {
  padding: 20px;
  background-color: #2d2d2d;
  color: white;
}

.base {
  background-color: #333;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -20px;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}



/* Highlight class */
.highlight {
  position: relative;
  color: #349dff;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

.highlight::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffdd57;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.highlight:hover {
  color: #1e90ff;
  background-color: rgba(255, 221, 87, 0.2);
}

.highlight:hover::before {
  transform: scaleX(1);
}


.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


/* Spaceship loading */
.spaceship-loading-container {
  position: fixed;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  z-index: 50;
}

.spaceship {
  width: 300px; /* Adjust size as needed */
  animation: flyAcross 1s ease-in-out infinite;
}

@keyframes flyAcross {
  0% {
      transform: translateX(-100vw) translateY(-50%);
  }
  50% {
      transform: translateX(50vw) translateY(-50%);
  }
  100% {
      transform: translateX(100vw) translateY(-50%);
  }
}
